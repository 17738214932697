import KindergartenMenu from "../Component/kindergartenMenu";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const KidsUniform = () => {
    return (
        <>
         <KindergartenMenu />
            <Breadcrumb title="Uniform" mid="Kindergarten &nbsp; &nbsp;|" tabName="Uniform" />
            <div className="innerPage">
                <div className="container">
                    <div class="kidsuniform row">
                        <div className="col-md-4 kidsZone"> 
                        <div class="kidsuniform_box1"><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Uniform.png" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad></div>
                           <div class="kidsuniform_box"><h6>The red coloured uniform with checks is symbolic of the children's world of colours and imagination. The red colour stands for the "LIFE" of the kids which is precious and in the process of growth. </h6></div>
                          
                        
                        </div>
                        <div className="col-md-8"> 
                            <div class="row kidsactivities_sec_img">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 min-height-section"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-1.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad></div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 min-height-section"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-2.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad></div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 min-height-section"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-3.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad></div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 min-height-section"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-4.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad></div>
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 min-height-section"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/uniform-5.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad></div>                            
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </div>
            <Footer />
        </>
    )
}
export default KidsUniform;