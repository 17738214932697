import { useState, useEffect } from 'react';
import KindergartenMenu from "../Component/kindergartenMenu";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';  // Assuming you're using react-router for navigation
import { getGalleryInner } from '../Service/Api';

const KinderActivities = () => {
  const [data, setData] = useState({
    curricularActivities: [],
    classroomActivities: [],
  });
  const [TabVal, setTabVal] = useState("1");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getGalleryInner();
      const curricularActivities = galleryData.filter(item => item.category === 'CurricularActivities' && item.school === 'Junior');
      const classroomActivities = galleryData.filter(item => item.category === 'ClassRoomActivities' && item.school === 'Junior');
      
      setData({
        curricularActivities,
        classroomActivities,
      });
    };
    
    fetchData();
  }, []);
    // Date formatting function
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
    
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.log("Invalid Date format:", isoDate);
            return 'Invalid Date'; // If the date can't be parsed, return "Invalid Date"
        }
    
        // Format the date as DD MM YYYY
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if single digit
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-11, so add 1)
        const year = date.getFullYear();
    
        return `${day}.${month}.${year}`; // Return in DD MM YYYY format
    };
  const handleTabClick = (value) => {
    setTabVal(value);
  };

  const renderActivityItems = (activities) => {
    return activities.length > 0 ? (
      activities.map((item, index) => (
        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount" key={index}>
          <div className="gal_new">
            <article>
              <LazyLoad>
                <img 
                  src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} 
                  alt={item.title || "Activity Image"} 
                  className="img-fluid" 
                />
              </LazyLoad>
              <div className="gal-content">
                <h4 className="catgry-name">{item.title}</h4>
                <p>{item.description}</p>
                <h6 className="date">
                  <i className="bi bi-calendar-week"></i> {formatDate(item.date)}
                  <span>
                    <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </span>
                </h6>
                <div className="total-img">
                  <i className="bi bi-card-image"></i><p>{item?.attachments.length}</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      ))
    ) : (
        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount">
        <div className="gal_new">
          <article>
            <LazyLoad>
              <img 
                src="https://webapi.entab.info/api/image/NCSR/public/Images/kinder-about.png"
                alt="Nirmala Convent School" 
                className="img-fluid" 
              />
            </LazyLoad>
            <div className="gal-content">
              <h4 className="catgry-name">Title</h4>
              <p>Stay Tuned For More Updates</p>
              <h6 className="date">
                <i className="bi bi-calendar-week"></i> DD.MM.YYYY
                <span>
                  
                    <i className="bi bi-arrow-right"></i>
                  
                </span>
              </h6>
              <div className="total-img">
                <i className="bi bi-card-image"></i><p>0</p>
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  };

  return (
    <>
      <KindergartenMenu />
      <Breadcrumb title="Activities" mid="Kindergarten &nbsp; &nbsp;|" tabName="Activities" />
      <div className="innerPage">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <ul className='list-view'>
                <li>Basic skills in speaking, listening, reading, and writing.</li>
                <li>Basic Mathematical concepts.</li>
                <li>Exploring potentials and developing skills.</li>
                <li>Personality development activities.</li>
                <li>Developing interpersonal skills and socially appropriate behavior.</li>
                <li>Spiritual orientation.</li>
                <li>Classwise assembly provides a platform at a very young age to overcome stage fear, develop confidence, and skills in public speaking.</li>
                <li>Class picnics are filled with fun and a unique opportunity to make new friends and cement happy relationships between teacher and child.</li>
                <li>Storytelling is a unique method to help them improve phonetics and vocabulary.</li>
              </ul>
            </div>
            <div className="col-xxl-12">
              <div className="button-list">
                <button className={`circular-activity tabClick1 ${TabVal === "1" ? "active" : ""}`} onClick={() => handleTabClick("1")}>
                  Curricular Activities
                </button>
                <button className={`classroom-activity tabClick1 ${TabVal === "2" ? "active" : ""}`} onClick={() => handleTabClick("2")}>
                  Classroom Activities
                </button>
              </div>
              {TabVal === "1" && (
                <div className="tab-section">
                  <div className="row kidsactivities_sec_img min-height-section">
                    {renderActivityItems(data.curricularActivities)}
                  </div>
                </div>
              )}

              {TabVal === "2" && (
                <div className="tab-section">
                  <div className="row kidsactivities_sec_img min-height-section">
                    {renderActivityItems(data.classroomActivities)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KinderActivities;
