import KindergartenMenu from "../Component/kindergartenMenu";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const GoalsRules = () => {
    return (
        <>
          <KindergartenMenu />
            <Breadcrumb title="Goals, Rules, Expectation" mid="Kindergarten &nbsp; &nbsp;|" tabName="Goals, Rules, Expectation" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-3 col-md-6 col-xs-12">
                            <div className="goal-rules">
                                <div className="title">
                                    <h2>Our Goals</h2>
                                </div> 
                                <p>Holy spirit aims at educating girls in the most appropriate way, making them self confident and an asset to the society. We want our children to show exemplary behaviour, excellent attitude, high level of attention and be socially sensitive.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-6 col-xs-12">
                            <div className="goal-rules">
                                <div className="title">
                                    <h2>Our Golden Rules Are</h2>
                                </div>  
                                <ul className='list-view'>
                                    <li>Be polite to every one and respect others.</li>
                                    <li>Be kind with your words and actions.</li>
                                    <li>Be honest and truthful.</li>
                                    <li>Be caring and learn to share.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-6 col-xs-12">
                            <div className="goal-rules">
                                <div className="title">
                                    <h2>Our Expectations</h2>
                                </div> 
                                <p>The educational goals and the moral values we envisage for the students cannot be achieved by the school alone. Therefore, we request the parents to co-operate and collaborate with the school in achieving these goals for your daughters through various activities.</p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-6 col-xs-12">
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/goal.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></LazyLoad>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}
export default GoalsRules;