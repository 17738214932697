import React, { useEffect, useState } from 'react';
import { getTopper } from '../Service/Api';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';

const TopperX = () => {
  const [classXData, setClassXData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();

        const currentYear = new Date().getFullYear();
        const currentSessionYear = `${currentYear - 1}-${currentYear}`;
        const previousSessionYear = `${currentYear - 2}-${currentYear - 1}`;

        // Filter for current session year data
        let classX = topperData.filter((item) => item.class === 'X' && item.sessionYear === currentSessionYear);

        // If no data found for the current session year, fallback to the previous session year
        if (classX.length === 0) {
          console.log(`No data found for ${currentSessionYear}. Falling back to ${previousSessionYear}.`);
          classX = topperData.filter((item) => item.class === 'X' && item.sessionYear === previousSessionYear);
        }

        setClassXData(classX);
        console.log("Filtered class X data:", classX);
      } catch (error) {
        console.error("Error fetching topper data:", error);
      }
    };

    fetchData();
  }, []);

  const emptyArray = [
    { name: 'Student Name', stream: 'Stream', division: '100%', attachments: 'topper.jpg' },
    { name: 'Student Name', stream: 'Stream', division: '100%', attachments: 'topper.jpg' },
    { name: 'Student Name', stream: 'Stream', division: '100%', attachments: 'topper.jpg' },
  ];

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      { breakpoint: 1400, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <Slider {...settings} className="topper-slider">
      {classXData.length > 0 ? (
        classXData.map((item, index) => (
          <div className="item" key={index}>
            <div className="topperdiv">
              <div className="topperimages">
                <LazyLoad>
                  <img
                    src={`https://webapi.entab.info/api/image/${item.attachments}`}
                    className="img-fluid tooperpicicn"
                    alt="Topper"
                  />
                </LazyLoad>
                <p>{item.division}</p>
              </div>
              <LazyLoad>
                <img
                  src="https://webapi.entab.info/api/image/NCSR/public/Images/topper-shape.png"
                  className="tpr-shape"
                  alt="Topper Shape"
                />
              </LazyLoad>
              <div className="topperdesc">
                <h5>{item.name}</h5>
                <p className="per">{item.stream}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        emptyArray.map((item, index) => (
          <div className="item" key={index}>
            <div className="topperdiv">
              <div className="topperimages">
                <LazyLoad>
                  <img
                    src="https://webapi.entab.info/api/image/NCSR/public/Images/topper.jpg"
                    className="img-fluid tooperpicicn"
                    alt="Topper"
                  />
                </LazyLoad>
                <p>{item.division}</p>
              </div>
              <LazyLoad>
                <img
                  src="https://webapi.entab.info/api/image/NCSR/public/Images/topper-shape.png"
                  className="tpr-shape"
                  alt="Topper Shape"
                />
              </LazyLoad>
              <div className="topperdesc">
                <h5>{item.name}</h5>
                <p className="per">{item.stream}</p>
              </div>
            </div>
          </div>
        ))
      )}
    </Slider>
  );
};

export default TopperX;
