import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
function alumni() {
    return (
        <>
            <Header />
            <Breadcrumb title="Alumni" tabName="Alumni " />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Dear Alumni,<br /><br />
                                Warm greetings from Nirmala Convent School (NCS), Rajkot!<br />
                                As a cherished member of the NCS family, we are thrilled to reconnect with you and strengthen our alumni network. We invite you to take a moment to register yourself through the link provided below. This platform will serve as a bridge to reconnect with your fellow Nirmala alumni, share memories, and stay updated on upcoming events and initiatives.
                                <br />
                                <a href='https://forms.gle/YZVCJQBkgdq8DLDA6' target='_blank'> <b>https://forms.gle/YZVCJQBkgdq8DLDA6</b></a><br />
                                Should you have any questions or need assistance, please feel free to reach out via email or the contact number provided in the registration form.<br />
                                We look forward to hearing from you and rebuilding the bonds that make the NCS community so special.<br />
                                Thank you, and we eagerly await your response!<br /><br />
                                Warm regards,<br />
                                NCS, Rajkot</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default alumni