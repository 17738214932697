import KindergartenMenu from "../Component/kindergartenMenu";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const KinderAbout = ()=>{
    return(
        <>
         <KindergartenMenu />
            <Breadcrumb title="About Us" mid="Kindergarten &nbsp; &nbsp;|" tabName="About Us" />
            <div className="innerPage">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12">
                            <h3> "A Child Is A Treasure House Of Possibilities."</h3>
                            <p>and we believe that the child is the most precious and valuable gift. Every child is unique and special. Their needs and dreams are different. So our objective is to focus on the all round development- of each child with human values ingrained in their soul. We believe that every child deserves the best and we have tried to provide a suitable atmosphere for the same.</p>
                            <h3>Holy Spirit Pre-Primary School</h3>
                            <p>The Kindergarten section is named Holy Spirit Pre-Primary School which is separately registered. The name Holy Spirit is significant as the little ones are placed under the guidance and protection of the Holy Spirit who stands for wisdom, knowledge and love of God.</p>
                            <p>"A school is a building with four walls and tomorrow inside."</p>
                            <h3>Our Theme</h3>
                            <p>Holistic education with a secure, stimulating and enjoyable learning.</p>
                            <h3>Our Vision</h3>
                            <p>To develop a just and humane society.</p>
                            <p>It's an apt place for children to celebrate their childhood. We understand the need of each child, hence the school has arranged its curriculum so as to provide each child their own space to grow and develop, and to express themselves.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default KinderAbout;