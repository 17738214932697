import React, { useEffect, useState } from 'react'
import Header from '../Component/Header'
import HomeSlider from '../Component/HomeSlider'
import News from '../Component/News'
import Events from '../Component/Events'
import TopperX from '../Component/TopperX'
import TopperXII from '../Component/TopperXII'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import Highlights from '../Component/Highlights'
import KinderNews from '../Component/KinderNews'
import Testimonial from '../Component/Testimonial'
import Notification from '../Component/Notification'
import VideoTestimonial from '../Component/VideoTestimonial'
import OnlineReg from '../Component/OnlineReg'
import LazyLoad from 'react-lazyload';

const Home = () => {



     return (
          <>
               <Notification />
               <Header />

               <div className="homeslider">
                    <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-md-3 padding-0'>
                                   <Highlights />
                              </div>
                              <div className='col-md-9 padding-0'>
                                   <HomeSlider />
                              </div>
                         </div>
                    </div>
                    <OnlineReg />
               </div>
               <div className='flex-block'>
                    <div>
                         <div className='bg-wt'>
                              <Link to="/cbse">
                                   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/cbseboard.gif" alt="Nirmala Convent School, Rajkot" />
                                   <h5>CBSE board</h5>
                              </Link>
                         </div>
                    </div>
                    <div>
                         <div className='bg-wt'>
                              <Link to="/alumni">
                                   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/alumni.gif" alt="Nirmala Convent School, Rajkot" />
                                   <h5>Alumni</h5>
                              </Link>
                         </div>
                    </div>
                    <div>
                         <div className='bg-wt'>
                              <Link to="/coming-soon">
                                   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/acheivement.gif" alt="Nirmala Convent School, Rajkot" />
                                   <h5>Achievements</h5>
                              </Link>
                         </div>
                    </div>
                    <div>
                         <Link to="/career">
                              <div className='bg-wt'>
                                   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/careers.gif" alt="Nirmala Convent School, Rajkot" />
                                   <h5>Careers</h5>
                              </div>
                         </Link>
                    </div>
                    <div>
                         <Link to="/erp-software">
                              <div className='bg-wt'>
                                   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/erp-soft.gif" alt="Nirmala Convent School, Rajkot" />
                                   <h5>ERP Software</h5>
                              </div>
                         </Link>
                    </div>
                    <div>
                         <Link to="/parent-portal">
                              <div className='bg-wt'>
                                   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/portal_help.gif" alt="Nirmala Convent School, Rajkot" />
                                   <h5>Portal (help)?</h5>
                              </div>
                         </Link>
                    </div>
               </div>
               <div className='main-background'>
                    <div className="aboutsec">
                         <div className='container'>
                              <div className='row'>
                                   <div className='col-lg-5'>
                                        <div className="abt-img">
                                             <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/about.png" className='img-fluid' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                        </div>
                                   </div>
                                   <div className='col-lg-7'>

                                        <div className='title'>
                                             <p>about us</p>
                                             <h2>nirmala convent school</h2>
                                        </div>


                                        <p>The first act in the history of <b>Nirmala Convent School</b> was played out in far away <b>Valencia, in Spain,</b> when the newly appointed Bishop of Ahmedabad, Rev. Monsignor Edwin Pinto, on a visit to his alma mator, persuaded the Bishop of Valencia to send some Spanish nuns to work in Rajkot. It was the involvement of the sisters of the Congregation of the Missionary Sisters of Christ Jesus including Sr. Maria Theresa Unzu in the educational field in Rajkot that paved the way for the establishment of Nirmala Convent School, on <b>14th June 1963.</b></p>
                                        <p>The school opened with classes from Nursery to standard III at Koyna House and had initially some 170 girls. The school laid down its permanent roots in Kalawad Road in 1970 and the first batch of students were presented for the SSC examinations in both English and Gujarati mediums in the year 1971. The ownership and management of the school was handed over by the Sisters of Christ Jesus to the Missionary Sisters Servants of the Holy Spirit on 1st April, 1985. Sr Rita took over the charge as the Principal of the school.</p>
                                        <p>At present, Nirmala proudly takes the responsibility of providing quality education and formation to more than 3450 girls. Nirmala Convent School has made a journey, through long and winding ways in the field of education and stood the ground during testing times, changing the mind set of the society towards the group called 'weaker sex' by empowering them to prove that they are strong in their resolve to be second to none in the society.</p>
                                        <Link to="/about">
                                             <button className='readMore'>
                                                  Read More
                                             </button>
                                        </Link>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="kindergarten">
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-6">
                                        <div className='title kinder'>
                                             <h2>Kindergarten</h2>
                                        </div>
                                        <div className='position-rel'>
                                             <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten.png" className='img-fluid' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                             <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kids-icon.png" className='img-fluid kinder-icon' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                             <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/btr-left.gif" className='kinder-icon-left' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                             <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/btr-right.gif" className='kinder-icon-right' alt="Nirmala Convent School, Rajkot" /></LazyLoad>

                                        </div>
                                        <div className='kinder-content'>
                                             <h4>Holy Spirit Pre-Primary School</h4>
                                             <p className='kinder-strip'>Nur, LKG, HKG</p>
                                        </div>

                                   </div>
                                   <div className="col-lg-6">
                                        <div className="kinder-news">
                                             <KinderNews />
                                             <Link to="/kindergarten-news"><button class="readMore">Read More</button></Link>
                                        </div>

                                   </div>

                              </div>
                         </div>
                    </div>
                    <div className="kindergarten senior">
                         <div className="container">
                              <div className="row">

                                   <div className="col-lg-6">
                                        <div className="kinder-news senior">
                                             <News />
                                             <Link to="/news"> <button class="readMore">Read More</button></Link>
                                        </div>
                                   </div>
                                   <div className="col-lg-6">
                                        <Link to="/cbse">
                                             <div className='title kinder'>
                                                  <h2>CBSE Section</h2>
                                             </div>
                                             <div className='position-rel'>
                                                  <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/cbse-news.png" className='img-fluid' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                                  <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/cbseIcon.gif" className='kinder-icon-right' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                             </div>
                                             <div className='kinder-content'>
                                                  <h4 className='mt-4'>Nirmala Convent School</h4>
                                                  <p className='kinder-strip'>Class 1 to 12</p>
                                             </div>
                                        </Link>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div class="testimonial">
                         <div className='container'>
                              <div className='row'>
                                   <div className='col-md-12'>
                                        <div className="title">
                                             <p>what people says about us</p>
                                             <h2>testimonial section</h2>
                                        </div>
                                   </div>
                                   <div className='col-md-8'>
                                        <Testimonial />
                                        <button className='readMore m-auto d-block mt-4'>View All</button>
                                   </div>
                                   <div className='col-md-4'>
                                        <div className='video-blk'>
                                             <h4>video testimonial</h4>
                                             <VideoTestimonial />
                                        </div>
                                   </div>
                              </div>
                         </div>

                    </div>
                    {/* <div className="homemsg">
                         <div className="container">
                              <div className="row">
                                   <div className="col-xl-12 col-lg-12">
                                        <div className="maintitle">
                                             <h2>Message section</h2>
                                        </div>
                                        <Message />

                                   </div>
                              </div>
                         </div>
                    </div> */}
                    <div className="toopersec">
                         <div className="toopersecbg">
                              {/* <div class="stripClass">
                                   <div className={`classtopper ${changeClassVal === "1" && "active"}`} onClick={changeClass1}>
                                        12TH
                                   </div>
                                   <div className={`classtopper ${changeClassVal === "2" && "active"}`} onClick={changeClass2}>
                                        10TH
                                   </div>
                              </div> */}
                              <div className="container fulwidth">
                                   <div className="row">

                                        <div className="col-lg-12 col-md-12">
                                             <div className='title'>
                                                  <p>toppers section</p>
                                                  <h2>our shining stars</h2>
                                             </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 topper12bx mt-4">
                                             <div>
                                                  <div className="title">
                                                       <h4><b>12th standard</b></h4>
                                                  </div>
                                                  <TopperXII />
                                                  <Link to="/topperXII"> <button class="readMore">Read More</button></Link>
                                             </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 topper12bx mt-4">
                                             <div>
                                                  <div className="title">
                                                       <h4><b>10th standard</b></h4>
                                                  </div>
                                                  <TopperX />
                                                  <Link to="/topperX"> <button class="readMore">Read More</button></Link>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="homeevent">
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-12 col-md-12">
                                        <div className='title'>
                                             <p>events section</p>
                                             <h2>What’s happening</h2>
                                        </div>
                                   </div>
                                   <div className='col-lg-12'>
                                        <div className='event-blk'>
                                             <Events />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <Footer />
          </>

     )
}

export default Home
