import react from 'react'
import KindergartenMenu from '../Component/kindergartenMenu';
import KindergartenSlider from '../Component/kindergarten-slider';
import { Link } from 'react-router-dom'
import KinderEvents from '../Component/KinderEvents';
import KinderNews from '../Component/KinderNews'
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';

const Kindergarten = () => {
    return (
        <> 
            <KindergartenMenu />
            <KindergartenSlider />
            <div class="flex-block kinderSection">
                <div>
                    <a href="/social-responsibility">
                        <div class="bg-wt">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cbseboard.gif" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                            <h5>Social</h5>

                        </div>
                    </a>
                </div>
                <div>
                    <a href="/kids-sports">
                        <div class="bg-wt">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/sports.gif" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                            <h5>Sports</h5>

                        </div>
                    </a>
                </div>
                <div>
                    <a href="/awards">
                        <div class="bg-wt">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/acheivement.gif" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                            <h5>Awards</h5>

                        </div>
                    </a>
                </div>
                <div>
                    <a href="/goals-rules">
                        <div class="bg-wt">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/notes.gif" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                            <h5>Rules</h5>
                        </div>
                    </a>
                </div>
                <div>
                    <a href="/cultural-programm">
                        <div class="bg-wt">
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/erp-soft.gif" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                            <h5>Program</h5>
                        </div>
                    </a>
                </div>
                <div>
                    <a href="/kinder-activities">
                        <div class="bg-wt">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/activities.gif" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                            <h5>Activities</h5>
                        </div>
                    </a>
                </div>
            </div>
            <div className='about-block'>
                <div className='container'>
                    <div className='row'>                        
                        <div className='col-md-6'>
                            <div className='abt-section'>
                            <div class="title"><p>Welcome to</p><h2> HSPP (Kindergarten) </h2></div>
                                
                                <p>"A child is a treasure house of possibilities."</p>
                                <p>and we believe that the child is the most precious and valuable gift. Every child is unique and special. Their needs and dreams are different. So our objective is to focus on the all round development of each child with human values ingrained in their soul. We believe that every child deserves the best and we have tried to provide a suitable atmosphere for the same.</p>
                                <p>Holy Spirit Pre-Primary School</p>
                                <p>The Kindergarten section is named Holy Spirit Pre-Primary School which is separately registered. The name Holy Spirit is significant as the little ones are</p>
                                <Link to="/kinder-about"><button className='read-more'>Read More</button></Link>
                            </div>
                        </div>
                        <div className='col-md-6'>
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kinder-about.png" className='img-fluid kinder_school' alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <div className='evt-news'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <div className='title'>
                                    <h2>Upcoming Events</h2>
                            </div>
                            <div className='events'>
                                <KinderEvents />
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='title'>
                                    <p>News</p>
                                    <h2>Latest News</h2>
                            </div>
                            <div className='events'>
                                <KinderNews />
                                <Link to="/kindergarten-news"><button class="readMore">Read More</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </>
    )
}
export default Kindergarten;