import React, { useEffect, useState } from "react";
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
import { form } from "../Service/Api";
import { getHeader } from "../config";
const Career = () => {
  const [showForm, setshowForm] = useState(false)
  const [imagePreview, setImagePreview] = useState("https://webapi.entab.info/api/image/NCSR/public/Images/user.png");
  const showFormButton = () => {
    setshowForm(prevState => !prevState); // Toggles the state
  }
  
  const [visible, setVisible] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
        dob: "",
        address: "",
        phone: "",
        phone1: "",
        webAddress: "",
        email: "",
        fatherName: "",
        profession: "",
        frPhone: "",
        motherName: "",
        profession1: "",
        mrPhone: "",
        spouseName: "",
        profession3: "",
        spPhone: "",
        depName: "",
        profession4: "",
        depPhone: "",
        attachments: null,
        qualification1: [{ qualification: "", school: "", percent: "", academicYear: "" }],
        careerDtl: [{ orgnisation: "", designation: "", fromYear: "", toYear: "", experience: "" }],
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if(formData) {
        console.log('qualification', formData);
    }
  }, [formData]);


  const showMore = () => {
    setFormData({
      ...formData,
      qualification1: [
        ...formData.qualification1,
        {
          qualification: '',
          school: '',
          percent: '',
          academicYear: ''
        },
      ]
    
    });
  };
  const showMore1 = () => {
    setFormData({
      ...formData,
      careerDtl: [
        ...formData.careerDtl,
        {
          orgnisation: '', designation: '', fromYear: '', toYear: '', experience: ''
        },
      ],
    });
  };

  // const removeQualification = (index, type) => {
  //   if (type === "qualification") {
  //     // Ensure qualification1 is an array before filtering
  //     const currentQualifications = Array.isArray(formData.qualification1)
  //       ? formData.qualification1
  //       : JSON.parse(formData.qualification1 || "[]");
  
  //     const newQualifications = currentQualifications.filter((_, idx) => idx !== index);
      
  //     setFormData({ ...formData, qualification1: JSON.stringify(newQualifications) });
  //   }
  // };

  const removeQualification = (index) => {
    const newQualifications = formData.qualification1.filter((_, idx) => idx !== index);
    setFormData({
      ...formData,
      qualification1: newQualifications,
    });
  };

  const removeCareerDtl = (index) => {
    const newcareerDtl = formData.careerDtl.filter((_, idx) => idx !== index);
    setFormData({
      ...formData,
      careerDtl: newcareerDtl,
    });
  };
  


  // const removeCareerDtl = (index, type) => {
  //   if (type === "careerDtl") {
  //     // Ensure qualification1 is an array before filtering
  //     const currentQualifications = Array.isArray(formData.qualification1)
  //       ? formData.qualification1
  //       : JSON.parse(formData.qualification1 || "[]");
  
  //     const newQualifications = currentQualifications.filter((_, idx) => idx !== index);
      
  //     setFormData({ ...formData, qualification1: JSON.stringify(newQualifications) });
  //   }
  // };
  


  const handleChange = (e, index = null) => {
    const { name, value, type, files } = e.target;
  
    if (type === "file" && name === "attachments1") {
      const file = files[0];
  
      if (file) {
        const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
        if (!validTypes.includes(file.type)) {
          setErrors({ ...errors, [name]: "Invalid file type. Only JPG, JPEG, and PDF are allowed." });
          return;
        }
  
        setFormData({ ...formData, [name]: file });
  
        // Show image preview
        if (file.type.startsWith("image/")) {
          const fileURL = URL.createObjectURL(file);
          setImagePreview(fileURL);
        }
      }
    } 
    if (type === "file" && name === "attachments") {
      const file = files[0];
  
      if (file) {
        const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
        if (!validTypes.includes(file.type)) {
          setErrors({ ...errors, [name]: "Invalid file type. Only JPG, JPEG, and PDF are allowed." });
          return;
        }
  
        setFormData({ ...formData, [name]: file });
  
        // Show image preview
      
      }
    } 
    // Check if it's an educational qualification field
    else if (name.startsWith("qualification") || name.startsWith("school") || name.startsWith("percent") || name.startsWith("academicYear")) {
      setFormData((prev) => {
        const updatedQualifications = [...(prev.qualification1 || [])]; // Ensure it's an array
        if (!updatedQualifications[index]) updatedQualifications[index] = {}; // Ensure object exists
        updatedQualifications[index][name.split("-")[0]] = value;
        return { ...prev, qualification1: updatedQualifications };
      });
    } 
    // Handle career details
    else if (name.startsWith("orgnisation") || name.startsWith("designation") || name.startsWith("fromYear") || name.startsWith("toYear") || name.startsWith("experience")) {
      setFormData((prev) => {
        const updatedCareerDtl = [...(prev.careerDtl || [])]; // Ensure it's an array
        if (!updatedCareerDtl[index]) updatedCareerDtl[index] = {}; // Ensure object exists
        updatedCareerDtl[index][name.split("-")[0]] = value;
        return { ...prev, careerDtl: updatedCareerDtl };
      });
    } 
    // Handle other fields
    else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  
    setErrors({ ...errors, [name]: "" });
  };
 

  const validateForm = () => {
    const newErrors = {};

    formData.qualification1.forEach((qualification, index) => {
      if (!qualification.qualification) newErrors[`qualification-${index}`] = 'Qualification is required';
      if (!qualification.school) newErrors[`school-${index}`] = 'school is required';
      if (!qualification.percent) newErrors[`percent-${index}`] = 'Marks are required';
      if (!qualification.academicYear) newErrors[`academicYear-${index}`] = 'academicYear is required'; 
    });
    formData.careerDtl.forEach((career, index) => {
      if (!career.orgnisation) newErrors[`orgnisation-${index}`] = "Organisation is required";
      if (!career.designation) newErrors[`designation-${index}`] = "Designation is required";
      if (!career.fromYear) newErrors[`fromYear-${index}`] = "From year is required";
      if (!career.toYear) newErrors[`toYear-${index}`] = "To year is required";
      if (!career.experience) newErrors[`experience-${index}`] = "Experience is required";
    });

    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.school1) newErrors.school1 = "Institution is required.";
    if (!formData.profession) newErrors.profession = "Designation is required.";
    if (!formData.fatherName) newErrors.fatherName = "Father / Spouse Name is required.";
    if (!formData.dob) newErrors.dob = "Date of Birth is required.";
    if (!formData.phone) newErrors.phone = "Contact Number is required.";
    if (!formData.mailType) newErrors.mailType = "Post Applied is required.";
    if (!formData.experience) newErrors.experience = "Number of Years is required.";
    if (!formData.attachments) newErrors.attachments = "Please upload CV (PDF only).";
    if (!formData.attachments1) newErrors.attachments1 = "Please upload CV (PDF only).";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.email) newErrors.email = "Email is required.";

    // File type validation for attachments
    if (formData.attachments) {
      const validTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
      if (!validTypes.includes(formData.attachments.type)) {
        newErrors.attachments = "Invalid file type. Only PDF are allowed.";
      }
    }
    if (formData.attachments1) {
      const validTypes1 = ['image/jpeg', 'image/jpg', 'application/pdf'];
      if (!validTypes1.includes(formData.attachments1.type)) {
        newErrors.attachments1 = "Invalid file type. Only PDF are allowed.";
      }
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log("Submit button clicked!");
    const dataToSend = { ...formData };
    dataToSend.qualification1 = JSON.stringify(dataToSend.qualification1);
    dataToSend.careerDtl = JSON.stringify(dataToSend.careerDtl);
  
    const processFile = (file) => {
      return new Promise((resolve, reject) => {
        if (!file || !(file instanceof File)) { // ✅ Check if file is valid
          resolve(null); 
          return;
        }
  
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result.split(",")[1]; 
          resolve({
            name: file.name,
            type: file.type,
            data: base64Data,
          });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };
  
    try {
        // Process first attachment
    const fileInput = document.getElementById("fileInput");
    if (fileInput && fileInput.files.length > 0) {
      dataToSend.attachments = await processFile(fileInput.files[0]);
    }

    // Process second attachment
    const fileInput1 = document.getElementById("fileInput1");
    if (fileInput1 && fileInput1.files.length > 0) {
      dataToSend.attachments1 = await processFile(fileInput1.files[0]);
    }
  
      console.log("Submitting form data:", JSON.stringify(formData));
      setSuccessMessage("Your Form Submitted Successfully.");
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxnwbYBP-HHqS0daU7ENbmjkE5RpbCTSfxXpewnNs88CjqoR-YbrN3UMjkGINoTP56XhA/exec",
        {mode: 'no-cors',
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSend),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log("Form submitted successfully:", result);
      setSuccessMessage("Your Form Submitted Successfully.");
  
      // Reset form data
      setFormData({
        name: "",
        dob: "",
        address: "",
        phone: "",
        phone1: "",
        webAddress: "",
        email: "",
        fatherName: "",
        profession: "",
        frPhone: "",
        motherName: "",
        profession1: "",
        mrPhone: "",
        spouseName: "",
        profession3: "",
        spPhone: "",
        depName: "",
        profession4: "",
        depPhone: "",
        attachments: null,
        attachments1: null,
        qualification1: [{ qualification: "", school: "", percent: "", academicYear: "" }],
        careerDtl: [{ orgnisation: "", designation: "", fromYear: "", toYear: "", experience: "" }],
      });
  
    } catch (error) {
      setSuccessMessage("Your Form Submitted Successfully.");
  
      // Reset form data
      setFormData({
        name: "",
        dob: "",
        address: "",
        phone: "",
        phone1: "",
        webAddress: "",
        email: "",
        fatherName: "",
        profession: "",
        frPhone: "",
        motherName: "",
        profession1: "",
        mrPhone: "",
        spouseName: "",
        profession3: "",
        spPhone: "",
        depName: "",
        profession4: "",
        depPhone: "",
        attachments: null,
        attachments1: null,
        qualification1: [{ qualification: "", school: "", percent: "", academicYear: "" }],
        careerDtl: [{ orgnisation: "", designation: "", fromYear: "", toYear: "", experience: "" }],
      });
    }
  };
  

  return (
    <>
      <Header />
      <Breadcrumb title="Career" tabName="Career" />
      <div className="innerPage">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <p><b>For the following subjects:</b></p>
              <ul>
                <li>Physics, Chemistry, Mathematics, Microbiology.(Post Graduates with B. Ed or interested in pursuing B. Ed)</li>
                <li>Social Studies & English (B.A, B.Ed. or M.A, B.Ed.)</li>
                <li>Physical Education (M.P.Ed.)</li>
                <li>Kindergarten: (Graduation with B.Ed. or Montessori)</li>
              </ul>
              {/* <p><b>How To Apply for any of the above position, please send your resumes</b></p>
                            <p><b>To CV </b> - <a href="mailto:  hrschoolrecruitment2024@gmail.com"> hrschoolrecruitment2024@gmail.com</a></p>
                            <p><b>OR Contact </b><a href="tel: 9586724824"> 9586724824</a></p> */}
              <p><strong>Note:</strong></p>
              <p> Salary will be determined based on qualification, experience, and performance in the demo class. (Attractive salary for deserving candidates)</p>
              <div class="clr"></div>
              <p><b>Managing Trustee</b></p>
              <p>NIRMALA CONVENT SCHOOL<br /> Nirmala Road, Rajkot - 360 001<br />Contact: +91 95867 24824</p>
               <div><button className='readMore m-2' onClick={showFormButton}>
               {showForm ? 'Apply' : 'Apply'}
                </button></div> 
            </div>
            <div className="col-md-5">
              <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/career.jpg" className="img-fluid" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
            </div>
            {showForm && (
              <div class="col-lg-12 mt-4 app-form">
              <h3 className="text-center">APPLICATION FORM FOR TEACHING POST</h3>
              <div className="career-form">

                <form className='careerform-feilds' onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-4 pos-rel">
                      <label>Professional Photograph</label>
                      <div className="mt-2">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="img-fluid"
                          style={{ maxWidth: "200px", borderRadius: "5px" }}
                        />
                      </div>
                      <input
                        className="form-control img-option"
                        type="file"
                        name="attachments1"
                        id="fileInput1"
                        accept=".jpg, .jpeg, .pdf"
                        onChange={handleChange}
                      />
                      {errors.attachments1 && <div className="error">{errors.attachments1}</div>}
 
                     
                    </div>
                    {/* Name */}
                    <div className="col-lg-8">
                      <div className="row">
                         
                        <div className="col-lg-6">
                          <label>Applicant Name</label>
                          <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
                          {errors.name && <div className="error">{errors.name}</div>}
                        </div>
                        <div className="col-lg-6">
                          <label>Date of Birth</label>
                          <input type="date" name="dob" className="form-control" value={formData.dob} onChange={handleChange} required />
                          {errors.dob && <div className="error">{errors.dob}</div>}
                        </div>

                        {/* Date of Birth */}
                        <div className="col-lg-6">
                          <label>Residential Address</label>
                          <input type="text" name="address" className="form-control" value={formData.address} onChange={handleChange} />
                          {errors.address && <div className="error">{errors.address}</div>}
                        </div>
                        <div className="col-lg-6">
                      <label>Mobile Number</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone" className="form-control" maxLength={10} value={formData.phone} onChange={handleChange} required />
                      {errors.phone && <div className="error">{errors.phone}</div>}
                    </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label>Alternative Number</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone1" className="form-control" maxLength={10} value={formData.phone1} onChange={handleChange} required />
                      {errors.phone1 && <div className="error">{errors.phone1}</div>}
                    </div>
                    {/* Phone */}
                    <div className="col-lg-4">
                      <label>Fixed Line</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="webAddress" className="form-control" maxLength={10} value={formData.webAddress} onChange={handleChange} />
                      {errors.webAddress && <div className="error">{errors.webAddress}</div>}
                    </div>
                    {/* Email */}
                    <div className="col-lg-4">
                      <label>Email</label>
                      <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} />
                      {errors.email && <div className="error">{errors.email}</div>}
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-lg-12 ">
                      <h3>Family Details:</h3>
                    </div>
                    {/* Father/Spouse Name */}
                    <div className="col-lg-4">
                      <label>Father’s Name</label>
                      <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange} required />
                      {errors.fatherName && <div className="error">{errors.fatherName}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Father Occupation/Designation</label>
                      <input type="text" name="profession" className="form-control" value={formData.profession} onChange={handleChange} required />
                      {errors.profession && <div className="error">{errors.profession}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Father Contact Number</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="frPhone" className="form-control" maxLength={10} value={formData.frPhone} onChange={handleChange} required />
                      {errors.frPhone && <div className="error">{errors.frPhone}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Mother's Name</label>
                      <input type="text" name="motherName" className="form-control" value={formData.motherName} onChange={handleChange} required />
                      {errors.motherName && <div className="error">{errors.motherName}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Mother Occupation/Designation</label>
                      <input type="text" name="profession1" className="form-control" value={formData.profession1} onChange={handleChange} required />
                      {errors.profession1 && <div className="error">{errors.profession1}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Mother Contact Number</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="mrPhone" className="form-control" maxLength={10} value={formData.mrPhone} onChange={handleChange} required />
                      {errors.mrPhone && <div className="error">{errors.mrPhone}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Spouse's Name</label>
                      <input type="text" name="spouseName" className="form-control" value={formData.spouseName} onChange={handleChange} />
                      {errors.spouseName && <div className="error">{errors.spouseName}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Spouse Occupation/Designation</label>
                      <input type="text" name="profession3" className="form-control" value={formData.profession3} onChange={handleChange} />
                      {errors.profession3 && <div className="error">{errors.profession3}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Spouse Contact Number</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="spPhone" className="form-control" maxLength={10} value={formData.spPhone} onChange={handleChange} />
                      {errors.spPhone && <div className="error">{errors.spPhone}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Dependent's Name</label>
                      <input type="text" name="depName" className="form-control" value={formData.depName} onChange={handleChange} />
                      {errors.depName && <div className="error">{errors.depName}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Dependent Occupation/Designation</label>
                      <input type="text" name="profession4" className="form-control" value={formData.profession4} onChange={handleChange} />
                      {errors.profession4 && <div className="error">{errors.profession4}</div>}
                    </div>
                    <div className="col-lg-4">
                      <label>Dependent Contact Number</label>
                      <input type="tel" pattern="\d{10}" inputMode="numeric" name="depPhone" className="form-control" maxLength={10} value={formData.depPhone} onChange={handleChange} />
                      {errors.depPhone && <div className="error">{errors.depPhone}</div>}
                    </div>

                  </div>

                  {/* Educational Qualification */}
                  <h3>Educational Credentials: <span onClick={showMore}><i className="bi bi-plus"></i></span></h3>
                  <p>(Please include all your educational qualifications, starting from STD-10/SSLC up to your most recent qualification)</p>
                  {formData.qualification1.map((qualification, index) => (
                    <div className="row pos-rel" key={index}>
                      {/* Qualification */}
                      <div className="col-lg-4">
                        <label>Qualification</label>
                        <input
                          type="text"
                          name={`qualification-${index}`}
                          className="form-control"
                          value={qualification.qualification}
                          onChange={(e) => handleChange(e, index)} required
                        />
                        {errors[`qualification-${index}`] && <div className="error">{errors[`qualification-${index}`]}</div>}
                      </div>

                      {/* Subject */}
                      <div className="col-lg-4">
                        <label>Institute/University/School with Board</label>
                        <input
                          type="text"
                          name={`school-${index}`}
                          className="form-control"
                          value={qualification.school}
                          onChange={(e) => handleChange(e, index)} required
                        />
                        {errors[`school-${index}`] && <div className="error">{errors[`school-${index}`]}</div>}
                      </div>

                      {/* Marks */}
                      <div className="col-lg-4">
                        <label>Percentage/Grade</label>
                        <input
                          type="text"
                          name={`percent-${index}`}
                          className="form-control"
                          value={qualification.percent}
                          onChange={(e) => handleChange(e, index)} required
                        />
                        {errors[`percent-${index}`] && <div className="error">{errors[`percent-${index}`]}</div>}
                      </div>

                      {/* Percentage */}
                      <div className="col-lg-4">
                        <label>Percentage</label>
                        <input
                          type="text"
                          name={`rate-${index}`}
                          className="form-control"
                          value={qualification.rate}
                          onChange={(e) => handleChange(e, index)} required
                        />
                        {errors[`rate-${index}`] && <div className="error">{errors[`rate-${index}`]}</div>}
                      </div>

                      {/* Institution */}
                      <div className="col-lg-4">
                        <label>Year of Completion</label>
                        <input
                          type="text"
                          name={`academicYear-${index}`}
                          className="form-control"
                          value={qualification.academicYear}
                          onChange={(e) => handleChange(e, index)} required
                        />
                        {errors[`academicYear-${index}`] && <div className="error">{errors[`academicYear-${index}`]}</div>}
                      </div>

                      {/* Remove Qualification */}
                      {index > 0 && (
      <div className="col-lg-12">
        <button type="button" onClick={() => removeQualification(index)} className="removebtn">-</button>
      </div>
    )}
                    </div>
                  ))}
                  {/* Educational Qualification */}
                  <h3>
                    Career Highlights:
                    <span onClick={showMore1}>
                      <i className="bi bi-plus"></i>
                    </span>
                  </h3>
                  {formData.careerDtl.map((careerDetail, index) => (
                    <div className="row pos-rel" key={index}>
                      {/* Organisation */}
                      <div className="col-lg-8">
                        <label>Organisation Name with Place</label>
                        <input
                          type="text"
                          name={`orgnisation-${index}`}
                          className="form-control"
                          value={careerDetail.orgnisation} // Correct reference
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors[`orgnisation-${index}`] && <div className="error">{errors[`orgnisation-${index}`]}</div>}
                      </div>

                      {/* Designation */}
                      <div className="col-lg-4">
                        <label>Position / Designation</label>
                        <input
                          type="text"
                          name={`designation-${index}`}
                          className="form-control"
                          value={careerDetail.designation}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors[`designation-${index}`] && <div className="error">{errors[`designation-${index}`]}</div>}
                      </div>

                      {/* From Year */}
                      <div className="col-lg-4">
                        <label>From</label>
                        <input
                          type="date"
                          name={`fromYear-${index}`}
                          className="form-control"
                          placeholder="DD-MM-YYYY"
                          value={careerDetail.fromYear}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors[`fromYear-${index}`] && <div className="error">{errors[`fromYear-${index}`]}</div>}
                      </div>

                      {/* To Year */}
                      <div className="col-lg-4">
                        <label>To</label>
                        <input
                          type="date"
                          name={`toYear-${index}`}
                          className="form-control"
                          value={careerDetail.toYear}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors[`toYear-${index}`] && <div className="error">{errors[`toYear-${index}`]}</div>}
                      </div>

                      {/* Experience */}
                      <div className="col-lg-4">
                        <label>Experience in YY-MM</label>
                        <input
                          type="text"
                          name={`experience-${index}`}
                          className="form-control"
                          value={careerDetail.experience}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {errors[`experience-${index}`] && <div className="error">{errors[`experience-${index}`]}</div>}
                      </div>

                      {/* Remove Career */}
                      {index > 0 && (
                        <div className="col-lg-12">
                          <button type="button" onClick={() => removeCareerDtl(index)} className="removebtn">
                            -
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="col-lg-4">
                    <label>Upload CV</label>
                    <input
                      className="form-control"
                      type="file"
                      name="attachments"
                      id="fileInput"
                      accept=".jpg, .jpeg, .pdf"
                      onChange={handleChange} required
                    />
                    {errors.attachments && <div className="error">{errors.attachments}</div>}
                  </div>

                  {/* Work Experience */}
                  {/* Submit Button */}
                  <div className="submit">
                    <input type="submit" value="Submit" />
                  </div>
                </form>

                {/* Success Message */}
                {successMessage && (
                  <div className="success-message">
                    <p>{successMessage}</p>
                  </div>
                )}
              </div>
            </div>
            )}
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Career;