import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer' 
const Magazine = ()=>{
    return(
        <>
        <Header />
            <Breadcrumb title="School Magazine" tabName="School Magazine" />
            <div className="innerPage">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-8"> 
                            <p><b>Beginning of a creative future.</b></p>
                            <p>Nirmalight - The School Magazine was launched in 2008. As the name suggests, it brings you the sparkle of the nirmal (pure) light of wisdom of Nirmalites. It is a milestone and means different things to different persons. It is a platform to bring out the latent talents of the students and encourage them to find their feet in the literary world. The magazine was aimed to help students in this infotech world to think faster, formulate and express ideas quickly and to develop a creative imagination to solve problems and respond to various issues of the society.</p>
                        </div>
                          <div className='col-md-4'>
                            <div className='magazine_blk'>
                                <a href="/magazine1" target="_blank">
                                    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/1.jpg" className='magazine-block img-fluid' />
                                    <button className='mt-4'>View</button>
                                </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Magazine;