import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home'
import About from './Pages/about'
import OurPatronees from './Pages/our-patronees'
import SchoolParliament from './Pages/school-parliament'
import VisionMission from './Pages/vision-mission'
import ClassroomActivity from './Pages/classroom-activity'
import CoCurricular from './Pages/co-curricular'
import SocialCommunityService from './Pages/social-community-service'
import Sports from './Pages/sports'
import Visitors from './Pages/visitors'
import CertificatesAwards from './Pages/certificates-awards'
import Administration from './Pages/administration'
import AdmissionProcedure from './Pages/admission-procedure'
import RulesDesicipline from './Pages/rules-desicipline'
import YearPlanner from './Pages/year-planner'
import SchoolHours from './Pages/school-hours'
import Contact from './Pages/contact'
import Career from "./Pages/career";
import ParentPortal from "./Pages/parent-portal";
import ErpSoftware from "./Pages/erp-software";
import CBSE from "./Pages/cbse";
import TC from "./Pages/tc";
import Campus from "./Pages/campus";
import News from "./Pages/news";
import Events from "./Pages/events";
import Gallery from "./Pages/gallery";
import SubGallery from "./Pages/SubGallery";
import KindergartenNews from "./Pages/kindergarten-news";
import ComingSoon from "./Pages/coming-soon";
import Magazine from "./Pages/magazine";
import Newsletter from "./Pages/newsletter";
import Kindergarten from "./Pages/kindergarten";
import KinderAbout from "./Pages/kinder-about";
import KinderEvents from "./Pages/kinder-events";
import SocialResponsibility from "./Pages/social-responsibility";
import KidsSports from "./Pages/kids-sports";
import Awards from "./Pages/awards";
import GoalsRules from "./Pages/goals-rules";
import CulturalProgramm from "./Pages/cultural-programm";
import KinderActivities from "./Pages/kinder-activities";
import KidsFeeDetails from "./Pages/kids-fee-details";
import KidsUniform from "./Pages/kids-uniform";
import KidsStrength from "./Pages/kids-strength";
import CoScholastic from "./Pages/coScholastic";
import Headmistress from "./Pages/headmistress"; 
import TopperX from "./Pages/topperX"; 
import TopperXII from "./Pages/topperXII"; 
import Magazine1 from "./Pages/magazine1"; 

import Alumni from "./Pages/alumni"; 


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our-patronees" element={<OurPatronees />} />
          <Route path="/school-parliament" element={<SchoolParliament />} />
          <Route path="/vision-mission" element={<VisionMission />} />
          <Route path="/classroom-activity" element={<ClassroomActivity />} />
          <Route path="/co-curricular" element={<CoCurricular />} />
          <Route path="/social-community-service" element={<SocialCommunityService />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/visitors" element={<Visitors />} />
          <Route path="/certificates-awards" element={<CertificatesAwards />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/admission-procedure" element={<AdmissionProcedure />} />
          <Route path="/rules-desicipline" element={<RulesDesicipline />} />
          <Route path="/year-planner" element={<YearPlanner />} />
          <Route path="/school-hours" element={<SchoolHours />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/parent-portal" element={<ParentPortal />} />
          <Route path="/erp-software" element={<ErpSoftware />} />
          <Route path="/cbse" element={<CBSE />} />
          <Route path="/tc" element={<TC />} />
          <Route path="/campus" element={<Campus />} />
          <Route path="/news" element={<News />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/SubGallery" element={<SubGallery />} />
          <Route path="/kindergarten-news" element={<KindergartenNews />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/magazine" element={<Magazine />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/kindergarten" element={<Kindergarten />} />
          <Route path="/kinder-about" element={<KinderAbout />} />
          <Route path="/kinder-events" element={<KinderEvents />} />
          <Route path="/social-responsibility" element={<SocialResponsibility />} />
          <Route path="/kids-sports" element={<KidsSports />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/goals-rules" element={<GoalsRules />} />
          <Route path="/cultural-programm" element={<CulturalProgramm />} />
          <Route path="/kinder-activities" element={<KinderActivities />} />
          <Route path="/kids-fee-details" element={<KidsFeeDetails />} />
          <Route path="/kids-strength" element={<KidsStrength />} />
          <Route path="/kids-uniform" element={<KidsUniform />} />
          <Route path="/coScholastic" element={<CoScholastic />} />
          <Route path="/headmistress" element={<Headmistress />} /> 
          <Route path="/topperX" element={<TopperX />} /> 
          <Route path="/topperXII" element={<TopperXII />} /> 
          <Route path="/magazine1" element={<Magazine1 />} /> 
          <Route path="/alumni" element={<Alumni />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;