import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import HTMLFlipBook from 'react-pageflip';
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'

const Magazine1 = () => {
    const flipBookRef = useRef();

    const goToNextPage = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipNext(); // Call flipNext method
        }
    };

    const goToPreviousPage = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipPrev(); // Call flipPrev method
        }
    };

    return (
        <>
           
            <div className="container">
                <div className="row inner-content">
                    <div className="col-md-12">
                        <div className="flipOpen">
                            <HTMLFlipBook
                                ref={flipBookRef}
                                width={600}
                                height={800}
                                minWidth={315}
                                maxWidth={1000}
                                minHeight={400}
                                mobileScrollSupport={true}
                                showCover={true}
                                useMouseEvents={true}
                                className="flipPageComponent"
                                pageBackground="rgb(255, 255, 255)"
                                uncutPages={true}
                            >
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/1.jpg" alt="Page 1" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/2.jpg" alt="Page 2" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/3.jpg" alt="Page 3" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/4.jpg" alt="Page 4" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/5.jpg" alt="Page 5" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/6.jpg" alt="Page 6" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/7.jpg" alt="Page 7" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/8.jpg" alt="Page 8" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/9.jpg" alt="Page 9" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/10.jpg" alt="Page 10" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/11.jpg" alt="Page 11" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/12.jpg" alt="Page 12" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/13.jpg" alt="Page 13" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/14.jpg" alt="Page 14" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/15.jpg" alt="Page 15" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/16.jpg" alt="Page 16" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/17.jpg" alt="Page 17" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/18.jpg" alt="Page 18" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/19.jpg" alt="Page 19" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/20.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/21.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/22.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/23.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/24.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/25.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/26.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/27.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/28.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/29.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/30.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/31.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/32.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/33.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/34.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/35.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/36.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/37.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/38.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/39.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/40.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/41.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/42.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/43.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/44.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/45.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/46.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/47.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/48.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/49.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/50.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/51.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/52.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/53.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/54.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/55.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/56.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/57.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/58.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/59.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/60.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/61.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/62.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/63.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/64.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/65.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/66.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/67.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/68.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/69.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/70.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/71.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/72.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/73.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/74.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/75.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/76.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/77.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/78.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/79.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/80.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/81.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/82.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/83.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/84.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/85.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/86.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/87.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/88.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/89.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/90.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/91.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/92.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/93.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/94.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/95.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/96.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/97.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/98.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/99.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/100.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/101.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/102.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/103.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/104.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/105.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/106.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/107.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/108.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/109.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/110.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/111.jpg" alt="Page 11" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/112.jpg" alt="Page 12" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/113.jpg" alt="Page 13" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/114.jpg" alt="Page 14" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/115.jpg" alt="Page 15" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/116.jpg" alt="Page 16" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/117.jpg" alt="Page 17" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/118.jpg" alt="Page 18" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/119.jpg" alt="Page 19" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/120.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/121.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/122.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/123.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/124.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/125.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/126.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/127.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/128.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/129.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/130.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/131.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/132.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/133.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/134.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/135.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/136.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/137.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/138.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/139.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/140.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/141.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/142.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/143.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/144.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/145.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/146.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/147.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/148.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/149.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/150.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/151.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/152.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/153.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/154.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/155.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/156.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/157.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/158.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/159.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/160.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/161.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/162.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/163.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/164.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/165.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/166.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/167.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/168.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/169.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/170.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/171.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/172.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/173.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/174.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/175.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/176.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/177.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/178.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/179.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/180.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/181.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/182.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/183.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/184.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/185.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/186.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/187.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/188.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/189.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/190.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/191.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/192.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/193.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/194.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/195.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/196.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/197.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/198.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/199.jpg" alt="Page 20" /></div>
                                <div><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine/200.jpg" alt="Page 20" /></div>
                                
                                {/* Add more pages as required */}
                            </HTMLFlipBook>
                        </div>
                        <div className="navigation-buttons">
                            <button onClick={goToPreviousPage} className="btn btn-primary">
                            <i class="bi bi-arrow-left"></i>
                            </button>
                            <button onClick={goToNextPage} className="btn btn-primary">
                            <i class="bi bi-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    );
};

export default Magazine1;
