import KindergartenMenu from "../Component/kindergartenMenu";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const KidsStrength = () => {
    return (
        <>
         <KindergartenMenu />
            <Breadcrumb title="Students Strength (Year 2024-2025)" mid="Kindergarten &nbsp; &nbsp;|" tabName="Students Strength (Year 2024-2025)" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="table-responsive table-wid">
                                <table className="table">
                                    <thead>
                                        <tr><th>Class </th>
                                            <th>Total Divisions</th>
                                            <th>Total intake</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nursery </td>
                                            <td>7</td>
                                            <td>238</td>
                                        </tr>
                                        <tr>
                                            <td>LKG</td>
                                            <td>7</td>
                                            <td>283</td>
                                        </tr>
                                        <tr>
                                            <td>HKG </td>
                                            <td>7</td>
                                            <td>274</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default KidsStrength;