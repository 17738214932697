import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Header from '../Component/Header';
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';

const TopperX = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("");
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const fetchData = async () => {
         try {
            const topperData = await getTopper();
            const currentYear = new Date().getFullYear();
            const currentSessionYear = `${currentYear - 1}-${currentYear}`;
            const previousSessionYear = `${currentYear - 2}-${currentYear - 1}`;

            // Filter data for Class X for the current session year
            let galleryData = topperData.filter((item) => item.class === 'X' && item.sessionYear === currentSessionYear);

            // Fallback to the previous session year if no data for the current session year
            if (galleryData.length === 0) {
               console.log(`No data found for ${currentSessionYear}. Falling back to ${previousSessionYear}.`);
               galleryData = topperData.filter((item) => item.class === 'X' && item.sessionYear === previousSessionYear);
               setSelectedYear(previousSessionYear); // Set fallback year as default
            } else {
               setSelectedYear(currentSessionYear); // Set current session year as default
            }

            setData(galleryData);

            // Extract unique session years from data
            const uniqueYears = Array.from(new Set(topperData.map((item) => item.sessionYear)));
            setYearRanges(uniqueYears);

            setFilteredData(galleryData); // Initialize filteredData with the relevant data
         } catch (error) {
            console.error("Error fetching topper data:", error);
         }
      };

      fetchData();
   }, []);

   useEffect(() => {
      // Filter data based on selected year and search query
      let filteredData = data;
      if (selectedYear !== "All") {
         filteredData = data.filter((item) => item.sessionYear === selectedYear);
      }
      if (searchQuery) {
         filteredData = filteredData.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
         );
      }

      // Sort data by division in descending order
      filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));

      setFilteredData(filteredData);
   }, [selectedYear, searchQuery, data]);

   return (
      <>
         <Header />
         <Breadcrumb title="Topper X" mid="Topper &nbsp; &nbsp;|" tabName="Topper X" />
         <div className="innerPage">
            <div className="container">
               <div className="row tabs-dynamic">
                  <div className="col-md-6">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection"
                        >
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row years year2023 showYear topper_page">
                  {filterData.length > 0 ? (
                     filterData.map((item, index) => (
                        <div className="col-md-3" key={index}>
                           <div className="topper-section services-style-current-theme text-center mb-lg-50 innertopper">
                              <div className="topper-service">
                                 <div className="thumb">
                                    <LazyLoad>
                                       <img
                                          className="topper-img"
                                          src={`https://webapi.entab.info/api/image/${item.attachments}`}
                                          alt="NCSR"
                                       />
                                    </LazyLoad>
                                 </div>
                                 <div className="details clearfix">
                                    <h4 className="title">{item?.name}</h4>
                                    <p>{item?.stream}</p>
                                    <ul className="topper-info-list">
                                       <li>
                                          <span className="font-weight-800 text-theme-colored3">Marks:</span> {item?.division}
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))
                  ) : (
                     <div className="col-12 text-center">No Data Available</div>
                  )}
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
};

export default TopperX;
